import { render, staticRenderFns } from "./FaqAjudaResumo.vue?vue&type=template&id=efb14f98&scoped=true&"
import script from "./FaqAjudaResumo.vue?vue&type=script&lang=js&"
export * from "./FaqAjudaResumo.vue?vue&type=script&lang=js&"
import style0 from "./FaqAjudaResumo.vue?vue&type=style&index=0&id=efb14f98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efb14f98",
  null
  
)

export default component.exports
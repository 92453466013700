<template>
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
        <!-- faq search section -->
        <section id="faq-search-filter">
            <b-card
                no-body
                class="faq-search"
                :style="{
          backgroundImage: `url(${require('@/assets/images/backgrounds/banner-faq.png')})`,
        }"
            >
                <b-card-body class="text-center">
                    <h2 class="text-white">Esclareça suas dúvidas</h2>

                    <!-- form -->
                    <b-form class="faq-search-input" @submit.prevent="">
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                                id="searchbar"
                                v-model="faqSearchQuery"
                                placeholder="Pergunte algo"
                                @change="getAjudas()"
                            />
                        </b-input-group>
                    </b-form>
                    <!-- form -->
                </b-card-body>
            </b-card>
        </section>
        <!--/ faq search section -->

        <!-- frequently asked questions tabs pills -->
        <section id="faq-tabs" class="mb-3">
            <b-tabs
                vertical
                pills
                content-class="col-12 col-md-8 col-lg-9"
                nav-wrapper-class="faq-navigation col-12 col-md-4 col-lg-3"
                nav-class="nav-left"
            >
                <!-- categorias tab -->
                <b-tab
                    v-for="(categoria, index) in faqData"
                    :key="index"
                    :active="categoriaSelecionada == categoria.titulo"
                >
                    <!-- title -->
                    <template #title>
                        <feather-icon v-if="categoria.icone != null"
                            :icon="categoria.icone"
                            size="18"
                            class="mr-1 icone-categoria"
                        />
                        <feather-icon v-else
                            icon="CircleIcon"
                            size="18"
                            class="mr-1 icone-categoria"
                        />
                        <span class="font-weight-bold">{{ categoria.titulo }}</span>
                    </template>
                    <FaqAjudaResumo
                        :options="categoria"
                        :icon="categoria.icone != null ? categoria.icone : 'CircleIcon'"
                    />
                </b-tab>
                <!--/ categorias tab -->
            </b-tabs>
        </section>
        <!--/ frequently asked questions tabs pills -->

        <!-- contact us -->
        <section class="faq-contact text-center bg-white p-2 pt-4">
            <b-row>
                <b-col lg="4" md="4" sm="12" class="mb-2">
                    <img :src=" require('@/assets/images/backgrounds/imagem-faq-duvidas-pessoa-pensativa.png')" alt="Ainda com dúvidas? Pessoa pensativa"/>
                </b-col>
                <b-col lg="8" md="8" sm="12" class="my-2">
                    <h2>Ainda com dúvidas?</h2>
                    <p>
                        Não conseguiu achar uma resposta para as suas dúvidas no nosso FAQ?<br />
                        Entre em contato conosco, responderemos o mais breve possível.
                    </p>

                    <SuporteDuvidasForm :FormularioDeDuvida="true" />
                </b-col>
            </b-row>
        </section>
        <!--/ contact us -->
    </b-overlay>
</template>

<script>
import {
    BCard,
    BCardBody,
    BForm,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BOverlay,
} from "bootstrap-vue";
import FaqAjudaResumo from "./FaqAjudaResumo.vue";
import SuporteDuvidasForm from "@/views/suporte-duvidas/SuporteDuvidasForm.vue";

export default {
    components: {
        BForm,
        BCard,
        BRow,
        BCol,
        BAvatar,
        BCardBody,
        BInputGroup,
        BFormInput,
        BCardText,
        BInputGroupPrepend,
        BTabs,
        BTab,
        BImg,
        FaqAjudaResumo,
        BLink,
        BOverlay,
        SuporteDuvidasForm
    },
    data() {
        return {
            faqSearchQuery: "",
            faqData: {},
            categoriaSelecionada: null,
            loadingShow: true
        };
    },
    beforeMount() {
        this.categoriaSelecionada = this.$route.params.categoria;
        this.getAjudas();
    },
    methods: {
        getAjudas() {
            this.loadingShow = true;
            this.$http.get(this.$api.AjudaSuporte, {
                params: {
                    pesquisa: this.faqSearchQuery,
                    categorizadas: true,
                    ativo: true
                }
            }).then((res) => {
                this.faqData = res.data;
                this.loadingShow = false;
            });
        }
    },
    watch: {
      '$route.params.categoria': function (novoValor) {
        this.categoriaSelecionada = novoValor
      }
    }
};
</script>

<style scopped lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";

#faq-tabs .nav-pills .nav-link.active,
#faq-tabs .nav-pills .show > .nav-link {
    background: rgb(240, 240, 240, 0);// #F5F5F5;
    color: $custom-purple;
    border: none;
    box-shadow: none;
    border-left: 2px solid;
    border-radius: 0;
}
.faq-navigation {
    height: auto;
    ul > li {
        display: block;
        width: 100%;
        word-break: break-all;
    }
}
.faq-search .faq-search-input .input-group {
    width: 70%;
}
</style>

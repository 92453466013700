<template>
  <div v-if="options">
    <div class="d-flex align-items-center">
      <b-avatar rounded size="42" variant="custom" class="mr-1">
        <feather-icon :icon="icon" size="20" />
      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ options.titulo }}
        </h4>
        <span class="descricao-personalizada" v-html="options.descricao" />
      </div>
    </div>
    <app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">
      <div v-if="options.ajudas.length != 0">
        <app-collapse-item
          v-for="ajuda in options.ajudas"
          :key="ajuda.id_ajuda"
          :title="ajuda.topico"
        >
          <span class="descricao-personalizada" v-html="ajuda.conteudo"></span>
        </app-collapse-item>
       </div>
     <div v-else>Nenhum registro encontrado.</div>
    </app-collapse>
  </div>
</template>

<script>
import { BAvatar, BLink } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BLink,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.descricao-personalizada::v-deep p {
  font-size: 1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 400;
  line-height: 1.45;
  color: #5e5873;
}
</style>
